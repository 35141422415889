<template>
    <div class="preview_area" v-if="modelValue" :class="{active : modelValue}" @click.self="closeModal()">
        <div class="report_section py-4">
            <div class="progress_container">
                <button class="close_btn" @click="closeModal()"><i class="fas fa-times"></i></button>
                <div class="section_header mt-4"><h2>Manage Contacts</h2></div>
                <ul class="tab_row border-bottom mb-5">
                    <li :class="manageTab === 'active' ? 'active' : ''"  :disable="loader" @click="manageTab = 'active'">Active</li>
                    <li :class="manageTab === 'completed' ? 'active' : ''"  :disable="loader" @click="manageTab = 'completed'">Completed</li>
                    <li :class="manageTab === 'deleted' ? 'active' : ''" :disable="loader" @click="manageTab = 'deleted'">Restricted</li>
                    <li :class="manageTab === 'optins' ? 'active' : ''"  :disable="loader" @click="manageTab = 'optins'">Opt-ins</li>
                </ul>
                <div class="tab_wpr" v-if="manageTab === 'active'">
                    <div class="result_wpr new">
                        <div class="actions">
                            <ul class="left">
                                <li class="optionDrops sort_list" @click="togglePerPageFilter('active')"  v-click-outside="closePageFilter">
                                    Show {{ activeParams.per_page }}<i class="fas fa-angle-down"></i>
                                    <page-filter v-model="activeParams.per_page" :is-dropdown="true" :type="2" ref="active-per-page-filter" />
                                </li>
                            </ul>
                            <ul class="right">
                                <li class="search_area" :class="{'active': searchField}">
                                    <input type="text" @input="isTyping = true" v-model.trim="activeParams.search" placeholder="Search"/>
                                    <button class="search_btn" @click="searchField = !searchField;">
                                        <i class="fas fa-search"></i>
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div class="contact_loader" v-if="loader"><quote-loader/></div>
                        <div class="scroll_table">
                            <table class="standard show_header" v-show="!loader">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Assigned At</th>
                                        <th>Actions</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody v-if="activeContacts.data && activeContacts.data.length">
                                    <tr v-for="(active, a) in activeContacts.data" :key="a">
                                        <td>
                                            <div class="user_wpr" @click="selectedContact = active.contact; profile = true">
                                                <h4>{{ active.contact.name }}</h4>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="email_wpr">{{ active.contact.email }}</div>
                                        </td>
                                        <td>
                                            {{ moment.utc(active.created_at).local().format('ll | LT') }}
                                        </td>
                                        <td>
                                            <ul class="action_list">
                                                <li v-if="!active.deleted_at" @click="handleResendReminder(active, 2)" title="Resend Sms reminder"><i class="fas fa-comment"></i></li>
                                                <li v-else class="action_left"></li>
                                                <li v-if="!active.deleted_at" @click="handleResendReminder(active, 1)" title="Resend email reminder"><i class="fas fa-envelope"></i></li>
                                                <li v-else class="action_left"></li>
                                                <li v-if="!active.deleted_at" @click="handleUnassignContact(active)"><i class="fas fa-trash-alt danger" title="Delete"></i></li>
                                                <li v-else class="action_left"></li>
                                                <!-- <li title="Preview"><i @click="handleWorkoutActivity(active)" class="fas fa-search-plus"></i></li> -->
                                            </ul>
                                        </td>
                                        <td class="action" @click="activeContact = active.contact; selectedContact = active.contact; launchPad = true;">
                                            <span v-tooltip="`Open your launchpad and perform actions`" position="left"><img src="@/assets/images/launchpad.svg"></span>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="8" class="px-4 text-center">No Records Found</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="table_footer">
                        <ul>
                            <li>{{ activeContacts.from ? activeContacts.from : 0 }} - {{ activeContacts.to ? activeContacts.to : 0 }} of <span>{{ activeContacts.total ? activeContacts.total : 0 }}</span></li>
                        </ul>
                    </div>
                </div>
                <div class="tab_wpr" v-if="manageTab === 'completed'">
                    <div class="result_wpr new">
                        <div class="actions">
                            <ul class="left">
                                <li class="optionDrops sort_list" @click="togglePerPageFilter('completed')"  v-click-outside="closeCompletedPageFilter">
                                    Show {{ activeParams.per_page }}<i class="fas fa-angle-down"></i>
                                    <page-filter v-model="activeParams.per_page" :is-dropdown="true" :type="2" ref="completed-per-page-filter" />
                                </li>
                            </ul>
                            <ul class="right">
                                <li class="search_area" :class="{'active': searchField}">
                                    <input type="text" @input="isTyping = true" v-model.trim="activeParams.search" placeholder="Search"/>
                                    <button class="search_btn" @click="searchField = !searchField;">
                                        <i class="fas fa-search"></i>
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div class="contact_loader" v-if="loader"><quote-loader/></div>
                        <div class="scroll_table">
                            <table class="standard show_header" v-show="!loader">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Assigned At</th>
                                        <th>Actions</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody v-if="completedContacts.data && completedContacts.data.length">
                                    <tr v-for="(completedContact, c) in completedContacts.data" :key="c">
                                        <td>
                                            <div class="user_wpr" @click="selectedContact = completedContact.contact; profile = true">
                                                <h4>{{ completedContact.contact.name }}</h4>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="email_wpr">{{ completedContact.contact.email }}</div>
                                        </td>
                                        <td>
                                            {{ moment.utc(completedContact.created_at).local().format('ll | LT') }}
                                        </td>
                                        <td>
                                            <ul class="action_list">
                                                <li v-if="!completedContact.deleted_at" @click="handleResendReminder(completedContact, 2)" title="Resend Sms reminder"><i class="fas fa-comment"></i></li>
                                                <li v-else class="action_left"></li>
                                                <li v-if="!completedContact.deleted_at" @click="handleResendReminder(completedContact, 1)" title="Resend email reminder"><i class="fas fa-envelope"></i></li>
                                                <li v-else class="action_left"></li>
                                                <li v-if="!completedContact.deleted_at" @click="handleUnassignContact(completedContact)"><i class="fas fa-trash-alt danger" title="Delete"></i></li>
                                                <li v-else class="action_left"></li>
                                                <li title="Preview"><i @click="handleWorkoutActivity(completedContact)" class="fas fa-search-plus"></i></li>
                                            </ul>
                                        </td>
                                        <td class="action" @click="activeContact = completedContact.contact; selectedContact = completedContact.contact; launchPad = true;">
                                            <span v-tooltip="`Open your launchpad and perform actions`" position="left"><img src="@/assets/images/launchpad.svg"></span>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="8" class="px-4 text-center">No Records Found</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="table_footer">
                        <ul>
                            <li>{{ completedContacts.from ? completedContacts.from : 0 }} - {{ completedContacts.to ? completedContacts.to : 0 }} of <span>{{ completedContacts.total ? completedContacts.total : 0 }}</span></li>
                        </ul>
                    </div>
                </div>
                <div class="tab_wpr" v-if="manageTab === 'deleted'">
                    <div class="result_wpr new">
                        <div class="actions">
                            <ul class="left">
                                <li class="optionDrops sort_list" @click="togglePerPageFilter('deleted')"  v-click-outside="closeDeletedPageFilter">
                                    Show {{ deletedParams.per_page }}<i class="fas fa-angle-down"></i>
                                    <page-filter v-model="deletedParams.per_page" :is-dropdown="true" :type="2" ref="deleted-per-page-filter" />
                                </li>
                            </ul>
                            <ul class="right">
                                <li class="search_area" :class="{'active': searchField}">
                                    <input type="text" @input="isTypingDeleted = true" v-model.trim="deletedParams.search" placeholder="Search"/>
                                    <button class="search_btn" @click="searchField = !searchField;">
                                        <i class="fas fa-search"></i>
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div class="contact_loader" v-if="loader"><quote-loader/></div>
                        <div class="scroll_table">
                            <table class="standard show_header" v-show="!loader">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Assigned At</th>
                                        <th>Actions</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody v-if="deletedContacts.data && deletedContacts.data.length">
                                    <tr v-for="(deletedContact, d) in deletedContacts.data" :key="d">
                                        <td>
                                            <div class="user_wpr" @click=" profile = true; selectedContact = deletedContact.contact;">
                                                <h4>{{ deletedContact.contact.name }}</h4>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="email_wpr">{{ deletedContact.contact.email }}</div>
                                        </td>
                                        <td>
                                            {{ moment.utc(deletedContact.created_at).local().format('ll | LT') }}
                                        </td>
                                        <td>
                                            <ul class="action_list">
                                                <li title="Unrestricted"><i class="fas fa-unlock-alt"></i></li>
                                                <li title="Preview"><i @click="handleWorkoutActivity(deletedContact.contact)" class="fas fa-search-plus"></i></li>
                                            </ul>
                                        </td>
                                        <td class="action" @click="activeContact = deletedContact.contact; selectedContact = deletedContact.contact; launchPad = true;">
                                            <span v-tooltip="`Open your launchpad and perform actions`" position="left"><img src="@/assets/images/launchpad.svg"></span>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="8" class="px-4 text-center">No Records Found</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="table_footer">
                        <ul>
                            <li>{{ deletedContacts.from ? deletedContacts.from : 0 }} - {{ deletedContacts.to ? deletedContacts.to : 0 }} of <span>{{ deletedContacts.total ? deletedContacts.total : 0 }}</span></li>
                        </ul>
                    </div>
                </div>
                <div class="tab_wpr" v-if="manageTab === 'optins'">
                    <div class="result_wpr new">
                        <div class="actions">
                            <ul class="left">
                                <li class="optionDrops sort_list" @click="togglePerPageFilter('optins')"  v-click-outside="closeOptinPageFilter">
                                    Show {{ optinParams.per_page }}<i class="fas fa-angle-down"></i>
                                    <page-filter v-model="optinParams.per_page" :is-dropdown="true" :type="2" ref="optins-per-page-filter" />
                                </li>
                            </ul>
                            <ul class="right">
                                <li class="search_area" :class="{'active': searchField}">
                                    <input type="text" @input="isTyping = true" v-model.trim="optinParams.search" placeholder="Search"/>
                                    <button class="search_btn" @click="searchField = !searchField;">
                                        <i class="fas fa-search"></i>
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div class="contact_loader" v-if="loader"><quote-loader/></div>
                        <div class="scroll_table">
                            <table class="standard show_header" v-show="!loader">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Assigned At</th>
                                        <th>Actions</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody v-if="optinContacts.data && optinContacts.data.length">
                                    <tr v-for="(optinContact, o) in optinContacts.data" :key="o">
                                        <td>
                                            <div class="user_wpr" @click=" profile = true; selectedContact = optinContact.contact;">
                                                <h4>{{ optinContact.contact.name }}</h4>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="email_wpr">{{ optinContact.contact.email }}</div>
                                        </td>
                                        <td>
                                            {{ moment.utc(optinContact.created_at).local().format('ll | LT') }}
                                        </td>
                                        <td>
                                            <ul class="action_list">
                                                <li v-if="!optinContact.deleted_at" @click="handleResendReminder(optinContact, 2)" title="Resend Sms reminder"><i class="fas fa-comment"></i></li>
                                                <li v-else class="action_left"></li>
                                                <li v-if="!optinContact.deleted_at" @click="handleResendReminder(optinContact, 1)" title="Resend email reminder"><i class="fas fa-envelope"></i></li>
                                                <li v-else class="action_left"></li>
                                                <li v-if="!optinContact.deleted_at" @click="handleUnassignContact(optinContact)"><i class="fas fa-trash-alt danger" title="Delete"></i></li>
                                                <li v-else class="action_left"></li>
                                                <li title="Preview"><i @click="handleWorkoutActivity(optinContact)" class="fas fa-search-plus"></i></li>
                                            </ul>
                                        </td>
                                        <td class="action" @click="activeContact = optinContact.contact; selectedContact = optinContact.contact; launchPad = true;">
                                            <span v-tooltip="`Open your launchpad and perform actions`" position="left"><img src="@/assets/images/launchpad.svg"></span>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="8" class="px-4 text-center">No Records Found</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="table_footer">
                        <ul>
                            <li>{{ optinContacts.from ? optinContacts.from : 0 }} - {{ optinContacts.to ? optinContacts.to : 0 }} of <span>{{ optinContacts.total ? optinContacts.total : 0 }}</span></li>
                        </ul>
                    </div>
                </div>
                <div class="model_pagination pb-4 mt-4">
                    <div class="pagination" v-show="activeContacts.total && manageTab == 'active'">
                        <pagination v-model="activeParams.page" :range-size="0" :pages="activeContacts.last_page" @update:modelValue="handleActivePagination" />
                    </div>
                    <div class="pagination" v-show="deletedContacts.total && manageTab === 'deleted'">
                        <pagination v-model="deletedParams.page" :range-size="0" :pages="deletedContacts.last_page" @update:modelValue="handleDeletedPagination" />
                    </div>
                    <div class="pagination" v-show="optinContacts.total && manageTab === 'optins'">
                        <pagination v-model="optinParams.page" :range-size="0" :pages="optinContacts.last_page" @update:modelValue="handleOptinPagination" />
                    </div>
                    <div class="pagination" v-show="completedContacts.total && manageTab === 'completed'">
                        <pagination v-model="completedParams.page" :range-size="0" :pages="completedContacts.last_page" @update:modelValue="handleCompletedPagination" />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <launch-pad v-model="launchPad" :contact="selectedContact" />
    <profile-component v-model="profile" :contact="selectedContact" />
    <workout-activity v-model="viewActivity" :workout="selectedAsset" :assign-id="selectedContact.id" :contact-id="selectedContact.contact_id" />
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions } from 'vuex'

    const LaunchPad = defineAsyncComponent(() => import('@/pages/contact/components/LaunchPad'))
    const ProfileComponent = defineAsyncComponent(() => import('@/pages/contact/components/Profile'))
    const WorkoutActivity = defineAsyncComponent(() => import('@/pages/workout/components/WorkoutActivity'))

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import moment from 'moment'

    export default {
        name: 'Workout Manage Contact',

        data () {
            return {
                manageTab: 'active',
                activeParams: {
                    page: 1,
                    per_page: 5,
                    search: '',
                    field: 'id',
                    order: 'desc',
                    id: '',
                    status: 'active'
                },
                deletedParams: {
                    page: 1,
                    per_page: 5,
                    search: '',
                    field: 'id',
                    order: 'desc',
                    id: '',
                    status: 'deleted',
                },
                optinParams: {
                    page: 1,
                    per_page: 5,
                    search: '',
                    field: 'id',
                    order: 'desc',
                    id: '',
                    status: 'optins',
                },
                completedParams: {
                    page: 1,
                    per_page: 5,
                    search: '',
                    field: 'id',
                    order: 'desc',
                    id: '',
                    status: 'completed',
                },
                searchField: false,
                isTyping: false,
                isTypingDeleted: false,
                isTypingOptin: false,
                isTypingCompleted: false,
                selectedContact: {},
                activeContact: {},
                filters: [
                    { title: 'Highest Overall Score', field: 'overall_score', order: 'desc'},
                    { title: 'Highest Participation Score', field: 'participation_score', order: 'desc'},
                    { title: 'Highest Progress Score', field: 'Progress_score', order: 'desc'},
                    { title: 'Lowest Overall Score', field: 'overall_score', order: 'asc'},
                    { title: 'Lowest Participation Score', field: 'participation_score', order: 'asc'},
                    { title: 'Lowest Progress Score', field: 'Progress_score', order: 'asc'},
                ],
                activeFilterTitle: 'Highest Overall Score',
                deletedFilterTitle: 'Highest Overall Score',
                optinFilterTitle: 'Highest Overall Score',
                completedFilterTitle: 'Highest Overall Score',
                launchPad: false,
                profile: false,
                mounted: false,
                moment,
                viewActivity: false,
            }
        },

        props: {
            modelValue: Boolean,
            selectedAsset: Object,
            refreshAsset: {
                type: Function,
                default: () => {},
            },
        },

        emit: ['update:modelValue'],

        components: {
            LaunchPad,
            ProfileComponent,
            WorkoutActivity,
        },

        watch: {
            modelValue (value) {
                const vm = this;

                vm.toggleScrollBar(value);

                if (value) {
                    vm.mounted = true;

                    vm.resetForm();

                    setTimeout(function () {
                        vm.getAssignContacts(vm.activeParams);
                        vm.getAssignContacts(vm.deletedParams);
                        vm.getAssignContacts(vm.optinParams);
                        vm.getAssignContacts(vm.completedParams);
                    }, 100);
                }

                if (value) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            'activeParams.per_page' () {
                const vm = this;

                vm.activeParams.page = 1;
                vm.getAssignContacts(vm.activeParams);
            },

            'deletedParams.per_page' () {
                const vm = this;

                vm.deletedParams.page = 1;
                vm.getAssignContacts(vm.deletedParams);
            },

            'optinParams.per_page' () {
                const vm = this;

                vm.optinParams.page = 1;
                vm.getAssignContacts(vm.optinParams);
            },

            'completedParams.per_page' () {
                const vm = this;

                vm.completedParams.page = 1;
                vm.getAssignContacts(vm.completedParams);
            },

            'activeParams.search' () {
                const vm = this;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.activeParams.search != null) {
                        if (vm.activeParams.search.length >= 2 || vm.activeParams.search.length === 0) {
                            vm.activeParams.page = 1;
                            vm.getAssignContacts(vm.activeParams);
                        }
                    }
                }
            },

            'deletedParams.search' () {
                const vm = this;

                setTimeout(() => {
                    vm.isTypingDeleted = false;
                }, 1500);
            },

            isTypingDeleted (val) {
                const vm = this;

                if (!val) {
                    if (vm.deletedParams.search != null) {
                        if (vm.deletedParams.search.length >= 2 || vm.deletedParams.search.length === 0) {
                            vm.deletedParams.page = 1;
                            vm.getAssignContacts(vm.deletedParams);
                        }
                    }
                }
            },

            'optinParams.search' () {
                const vm = this;

                setTimeout(() => {
                    vm.isTypingOptin = false;
                }, 1500);
            },

            isTypingOptin (val) {
                const vm = this;

                if (!val) {
                    if (vm.optinParams.search != null) {
                        if (vm.optinParams.search.length >= 2 || vm.optinParams.search.length === 0) {
                            vm.optinParams.page = 1;
                            vm.getAssignContacts(vm.optinParams);
                        }
                    }
                }
            },

            'completedParams.search' () {
                const vm = this;

                setTimeout(() => {
                    vm.isTypingCompleted = false;
                }, 1500);
            },

            isTypingCompleted (val) {
                const vm = this;

                if (!val) {
                    if (vm.completedParams.search != null) {
                        if (vm.completedParams.search.length >= 2 || vm.completedParams.search.length === 0) {
                            vm.completedParams.page = 1;
                            vm.getAssignContacts(vm.completedParams);
                        }
                    }
                }
            },
        },

        computed: mapState({
            loader: state => state.workoutModule.workoutPlanComponentLoader,
            activeContacts: state => state.workoutModule.activeContacts,
            deletedContacts: state => state.workoutModule.deletedContacts,
            optinContacts: state => state.workoutModule.optinContacts,
            completedContacts: state => state.workoutModule.completedContacts,
        }),

        methods: {
            ...mapActions ({
                getAssignContacts: 'workoutModule/getAssignContacts',
                resendReminder: 'workoutModule/resendReminder',
                unassignContacts: 'workoutModule/unassignContacts',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            togglePerPageFilter (value) {
                const vm = this;
                const filter = vm.$refs[`${value}-per-page-filter`];

                if (filter) {
                    filter.dropdown = !filter.dropdown;
                }
            },

            closePageFilter () {
                const vm = this;
                const filter = vm.$refs['active-per-page-filter'];

                if (filter) {
                    filter.dropdown = false;
                }
            },

            closeDeletedPageFilter () {
                const vm = this;
                const filter = vm.$refs['deleted-per-page-filter'];

                if (filter) {
                    filter.dropdown = false;
                }
            },

            closeOptinPageFilter () {
                const vm = this;
                const filter = vm.$refs['deleted-per-page-filter'];

                if (filter) {
                    filter.dropdown = false;
                }
            },

            closeCompletedPageFilter () {
                const vm = this;
                const filter = vm.$refs['deleted-per-page-filter'];

                if (filter) {
                    filter.dropdown = false;
                }
            },

            handleActivePagination () {
                const vm = this;

                vm.getAssignContacts(vm.activeParams);
            },

            handleDeletedPagination () {
                const vm = this;

                vm.getAssignContacts(vm.deletedParams);
            },

            handleOptinPagination () {
                const vm = this;

                vm.getAssignContacts(vm.optinParams);
            },

            handleCompletedPagination () {
                const vm = this;

                vm.getAssignContacts(vm.completedParams);
            },

            sortingFilter (filter, type) {
                const vm = this;

                if (type == 'active') {
                    vm.activeFilterTitle  = filter.title;
                    vm.activeParams.field = filter.field;
                    vm.activeParams.order = filter.order;

                    vm.getAssignContacts(vm.activeParams);
                }

                if (type == 'deleted') {
                    vm.deletedFilterTitle  = filter.title;
                    vm.deletedParams.field = filter.field;
                    vm.deletedParams.order = filter.order;

                    vm.getAssignContacts(vm.deletedParams);
                }

                if (type == 'optins') {
                    vm.optinFilterTitle  = filter.title;
                    vm.optinParams.field = filter.field;
                    vm.optinParams.order = filter.order;

                    vm.getAssignContacts(vm.optinParams);
                }

                if (type == 'completed') {
                    vm.completedFilterTitle  = filter.title;
                    vm.completedParams.field = filter.field;
                    vm.completedParams.order = filter.order;

                    vm.getAssignContacts(vm.completedParams);
                }
            },

            resetForm () {
                const vm = this;

                vm.manageTab    = 'active';
                vm.activeParams = {
                                        id: vm.selectedAsset.id,
                                        page: 1,
                                        per_page: 5,
                                        search: '',
                                        field: 'id',
                                        order: 'desc',
                                        status: 'active',
                                        assetType: 'workout',

                                    };
                vm.deletedParams = {
                                        id: vm.selectedAsset.id,
                                        page: 1,
                                        per_page: 5,
                                        search: '',
                                        field: 'id',
                                        order: 'desc',
                                        status: 'deleted',
                                        assetType: 'workout',
                                    };
                vm.optinParams = {
                                        id: vm.selectedAsset.id,
                                        page: 1,
                                        per_page: 5,
                                        search: '',
                                        field: 'id',
                                        order: 'desc',
                                        status: 'optins',
                                        assetType: 'workout',
                                    };
                vm.completedParams = {
                                        id: vm.selectedAsset.id,
                                        page: 1,
                                        per_page: 5,
                                        search: '',
                                        field: 'id',
                                        order: 'desc',
                                        status: 'completed',
                                        assetType: 'workout',
                                    };
            },

            handleResendReminder (contact, type) {
                const vm = this;

                const options = Helper.swalConfirmOptions('Are you sure?', `You want to resend ${ type == 1 ? 'email' : 'SMS' } reminder for this contact?`);

                options.preConfirm = function () {
                                        const params = {
                                            assign_id: contact.id,
                                            contact_id: contact.contact_id,
                                            id: contact.workout_id,
                                            type,
                                            assetType: 'workout',
                                        };

                                        return vm.resendReminder(params);
                                    };

                Swal.fire(options);
            },

            handleUnassignContact (contact) {
                const vm = this;

                const options = Helper.swalConfirmOptions('Are you sure?', `You want to stop workout for this contact?`);

                options.preConfirm = function () {
                                        return vm.unassignContacts({ contacts: [contact.contact_id], ids: [contact.workout_id], assetType: 'workout' }).then((result) => {
                                            if (result) {
                                                vm.activeParams.search      = '';
                                                vm.deletedParams.search     = '';
                                                vm.optinParams.search       = '';
                                                vm.completedParams.search   = '';

                                                vm.getAssignContacts(vm.activeParams);
                                                vm.getAssignContacts(vm.deletedParams);
                                                vm.getAssignContacts(vm.optinParams);
                                                vm.getAssignContacts(vm.completedParams);
                                                vm.refreshAsset();
                                            }
                                        })
                                    };

                Swal.fire(options);
            },

            handleWorkoutActivity (contact) {
                const vm = this;

                vm.viewActivity = true;
                vm.selectedContact = contact;
            },
        },
    }
</script>

<style scoped>
    .share_playbook .result_wpr {
        width: 100%;
    }

    .row_option li a {
        color: inherit;
        text-decoration: none;
    }

    .action_list {
        display: flex;
        flex-wrap: wrap;
    }

    .action_list li {
        margin-right: 5px;
        cursor: pointer;
    }

    .action_list li i {
        color: #2f7eed;
    }

    .model_pagination {
        display: flex;
        padding: 0px 35px;
        justify-content: flex-end;
        align-items: center;
    }

    .action_list .action_left {
        padding-left: 21px;
    }

    .progress_container {
        max-width: 1170px;
        padding: 0 20px;
        margin: 0 auto;
    }
    .preview_area{
        background: #f5f5f5;
    }
    .preview_area .close_btn {
        right: 15px;
        top: 15px;
        left: auto;
        position: fixed;
        background: #eaeaea;
        width:25px;
        height: 25px;
        border-radius: 50%;
        cursor: pointer;
    }

    .modal .preview_area.show_sidebar {
        left: 0;
        width: 100%;
    }

    .result_wpr table td .score_circle {
        display: inline-block;
    }

    .result_wpr table td .score_circle .inner_circle .score {
        font-size: 11px;
        line-height: 13px;
    }

    .result_wpr table td .score_circle .inner_circle .score span {
        font-size: 9px;
        line-height: 11px;
    }

    .result_wpr table td .score_circle .inner_circle .score {
        font-size: 11px;
        line-height: 13px;
    }

    .result_wpr table td .score_circle .inner_circle .score span {
        font-size: 9px;
        line-height: 11px;
    }

    .result_wpr table td .score_circle {
        display: inline-block;
    }

    .result_wpr table td .score_circle .inner_circle .score {
        font-size: 11px;
        line-height: 13px;
    }

    .result_wpr table td .score_circle .inner_circle .score span {
        font-size: 9px;
        line-height: 11px;
    }

    .result_wpr.small .actions > ul {
        height: 35px;
    }

    .result_wpr.small .actions > ul li, .result_wpr.small .actions > ul li input {
        padding: 7px 10px;
        font-size: 14px !important;
    }

    .result_wpr.small .actions > ul li .dropdown_wpr {
        width: 100%;
    }

    .result_wpr.small table td  {
        padding: 12px;
        font-size: 13px;
        line-height: 16px;
    }

    .result_wpr.small table th {
        padding: 7px 12px;
        font-size: 12px;
        line-height: 16px;
    }

    .result_wpr.small table td .user_img, .result_wpr.small table td .user_img img {
        width: 30px;
        height: 30px;
    }

    .result_wpr.small table td h4  {
        font-size: 13px;
        line-height: 16px;
        color: #2f7eed;
        font-weight: 500;
    }

    .result_wpr table td:not(:first-child, :last-child, :nth-child(2)) * {
        margin: 0 auto;
    }

    .result_wpr table td .score_circle {
        display: inline-block;
    }

    .result_wpr table td .score_circle .inner_circle .score {
        font-size: 11px;
        line-height: 13px;
    }

    .result_wpr table td .score_circle .inner_circle .score span {
        font-size: 9px;
        line-height: 11px;
    }

    .result_wpr table.standard td:first-child, .result_wpr table.standard th:first-child {
        width: auto;
        text-align: left;
        padding-left: 15px;
    }

    .share_playbook .tab_row, .report_section .tab_row {
        padding: 20px 0 0 0;
        display: flex;
        gap: 30px;
    }

    .share_playbook .tab_row li, .report_section .tab_row li {
        padding-bottom: 12px;
        position: relative;
        cursor: pointer;
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        color: #121525;
        overflow: hidden;
    }
    .share_playbook .tab_row li:after, .report_section .tab_row li:after {
        position: absolute;
        content: '';
        left: -100%;
        bottom: 0;
        width: 100%;
        border-bottom: 1px solid #2F80ED;
        transition: all 0.3s ease-in-out;
    }

    .share_playbook .tab_row li.active, .report_section .tab_row li.active {
        color: #2f7eed;
    }
    .share_playbook .tab_row li.active:after, .report_section .tab_row li.active:after{
        left: 0;
    }

    .leaderboard_record {
        justify-content: center;
    }

    .leaderboard_record ul li {
        padding: 6px 15px;
        display: flex;
        align-items: center;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
    }

    .result_wpr table td.action span img {
        width: 16px;
        height: 16px;
        cursor: pointer;
    }
</style>
